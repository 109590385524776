<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="collectionsData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingCollections"
      loading-text="Cargando Cobros..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por descripción / monto / etc."
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        </v-toolbar>
      </template>

     

      <template v-slot:item.actions="{ item }">
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="((rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado'))"
              size="18"
              class="me-3"
              v-bind="attrs"
              color="success"
              v-on="on"
              @click="aprobarRendicionItem(item)"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </template>
          <span>Aprobar Rendicion</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <dialogo
      ref="dialog"
      :mensaje="mensaje"
      @confirmar="aprobarRendicion(rendicionitem.id)"
    ></dialogo>
  </v-container>
</template>

<script>
import {
  mdiPenPlus, mdiTagPlus, mdiDeleteEmpty, mdiCheckCircle, mdiCancel,
} from '@mdi/js'

import axios from 'axios'
import moment from 'moment'
import dialogo from '../../../components/base/DialogGlobal.vue'

export default {
  components: { dialogo },
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data: () => ({
    rendicionitem: null,
    mensaje: '¿Está seguro que desea conciliar la rendición ?',
    monto: null,
    icons: {
      mdiPenPlus,
      mdiTagPlus,
      mdiDeleteEmpty,
      mdiCheckCircle,
      mdiCancel,
    },
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: true,
        value: 'id',
      },

      { text: 'Cobrador', value: 'collector' },
      { text: 'Cliente', value: 'client' },
      { text: 'Monto Pagado', value: 'amount' },
      { text: 'Fecha', value: 'date' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    collectionsData: [],
    buscar: '',
    loadingCollections: false,
    mensajeSnack: '',
    cashRegister: null,
  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },
  mounted() {
    this.getCollectionsToBill()
    this.getCashRegisterFromStore()
  },
  methods: {

    aprobarRendicionItem(item) {
      this.mensaje= '¿Está seguro que desea conciliar la rendición por $'+ item.amount + ' ?'
      this.rendicionitem = item
      this.$refs.dialog.OpenDialog()
    },
    aprobarRendicion(item) {
      this.aproveDesaproveCollectionToBill(item)
    },
    getCashRegisterFromStore() {
      this.cashRegister = this.$store.getters.caja
 

    },
    async aproveDesaproveCollectionToBill(quotaPerCreditID) {
      this.loadingCollections = true
      

      this.url = `${this.$store.getters.urlBase}/api/Credit/confirmCollectorCollection`

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params: { collectionPerQuotaId: parseInt(quotaPerCreditID), personIDLogged: parseInt(this.$store.getters.usuario.user_personid) },
        url: this.url,
      }
      const respuesta = await axios(options).catch(error => {
        if (error.response.data) {
          this.mensajeSnack = `Error: ${error.response.data}`
        } else if (error.message) {
          this.mensajeSnack = `Error: ${error.message}`
        } else {
          this.mensajeSnack = 'Error de Conexion'
        }
        this.loadingCollections = false
        this.$store.dispatch('showSnack', {
          text: this.mensajeSnack,
          color: 'error',
          timeout: 2500,
        })
      })
      if (respuesta.status === 200) {
        this.loadingCollections = false
        this.$store.dispatch('showSnack', {
          text: 'Operacion Exitosa',
          color: 'success',
          timeout: 2500,
        })
      }
      this.getCollectionsToBill()
    },

    async getCollectionsToBill() {
      this.url = `${this.$store.getters.urlBase}/api/Credit/CollectionCollectorToBill`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        url: this.url,
      }
      this.loadingCollections = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.collectionsData = respuesta.data
        this.loadingCollections = false
      } else {
        this.loadingCollections = false
      }
    },

  },
}
</script>
